<template>
  <v-dialog v-model="dialogOpen" max-width="650px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" fixed top dark>
          <v-icon @click="cancel()">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Filtersuche mit Wildcards</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="dialogTab" grow>
          <v-tab key="generalTab"> Allgemein </v-tab>
          <v-tab key="examplesTab"> Beispiele </v-tab>
        </v-tabs>

        <v-tabs-items v-model="dialogTab" class="pt-3">
          <v-tab-item key="generalTab">
            Filtersuchregeln:
            <ul>
              <li>
                Bei der Filtersuche mit Wildcards werden Unterschiede in Groß-
                und Kleinschreibung zur Vereinfachung ignoriert.
              </li>
              <li>Alle Filter können beliebig kombiniert werden.</li>
              <li>
                Wenn ein Filter verwendet wird und kein Wildcard-Zeichen darin
                enthalten ist, so wird <b>genau</b> nach diesem Suchtext im
                entsprechenden Feld gesucht. "Mustermann" ergibt daher kein
                Ergebniss, das exakt übereinstimmende "Mustermann Max" hingegen
                schon.
              </li>
              <li>
                Wenn Wildcard-Zeichen enthalten sind, dann wird unscharf
                gesucht. Der Suchtext ist in diesem Fall eine Schablone für die
                gewünschten Antworten.
              </li>
              <li>
                Die folgenden Felder fungieren per Wildcard-Suche: Name, Straße,
                PLZ und Ort.
              </li>
            </ul>
            <br />
            Die Suche kann mit folgenden Wildcards zugeschnitten und erweitert
            werden:
            <ul>
              <li><b>*</b> - ist ein Platzhalter für beliebig viele Zeichen</li>
              <li><b>?</b> - ist ein Platzhalter für genau ein Zeichen</li>
            </ul>
          </v-tab-item>

          <v-tab-item key="examplesTab">
            <ul>
              <li>
                <b>"Mustermann Max"</b> in 'Name': liefert nur Adressen, deren
                Name exakt genau "Mustermann Max" ist, da keine Wildcard-Zeichen
                enthalten sind.
              </li>
              <li>
                <b>"Adler*"</b> in 'Name': alle Adressen die genau mit "Adler"
                beginnen, alle danach folgenden Zeichen sind egal. Es werden
                sowohl "Adler Maria" als auch "Adlermayer Hermann" gefunden.
              </li>
              <li>
                <b>"*Lukas"</b> in 'Name': alle Adressen, die genau mit "Lukas"
                enden.
              </li>
              <li>
                <b>"????straße*"</b> in 'Straße': alle Adressen, deren Straße
                mit beliebigen vier Zeichen beginnt, danach Straße enthält und
                beliebig endet. Zum Beispiel: Wolfstraße, Märzstraße usw.
              </li>
              <li>
                <b>"*straße"</b> in 'Straße' (man beachte das fehlende Sternchen
                am Ende): alle Adressen die mit "straße" (oder "Straße") enden,
                also bei denen keine Hausnummer hinterlegt ist.
              </li>
              <li>
                <b>"40??"</b> in 'PLZ': alle Adressen, deren Postleitzal mit 40
                beginnt, also von 4000 bis inklusive 4099.
              </li>
            </ul>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="cancel()">
          Verstanden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialogOpen: false,
    dialogTab: null
  }),
  methods: {
    cancel() {
      this.dialogOpen = false
    }
  }
}
</script>

<style></style>
