<template>
  <v-container>
    <div>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="9">
          <v-text-field
            class="px-8 mt-8"
            v-model="fullTextSearchInput"
            append-icon="mdi-magnify"
            label="Textsuche (mind. 3 Zeichen)"
            :rules="[twoCharactersValidation]"
            clearable
            @input="searchTrigger"
            underlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <AddressFilterDialog
            @toggle="filterSet = !filterSet"
            @searchTriggered="initiateSearchUI(), scrollToResults()"
            @searchSuccess="setResults"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-6"></v-divider>
      <div ref="resultsTable">
        <v-data-table
          :headers="headers"
          :items="results"
          :items-per-page="10"
          item-key="lfdnr"
          class="mt-6 datatable-row-pointer"
          @click:row="openAddressDataTable"
          :loading="loading"
          loading-text="Adressen werden geladen ..."
          v-if="results.length > 0"
        >
          <template v-slot:item.name="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-avatar
                  v-on="on"
                  size="34"
                  :color="item.active ? 'white' : 'red'"
                  class="mr-4 elevation-2"
                >
                  <v-icon :color="item.active ? 'black' : 'white'">{{
                    addressType(item).icon
                  }}</v-icon>
                </v-avatar>
              </template>
              <span>{{ addressType(item).name }}</span
              ><br />
              <span v-if="!item.active">inaktiv</span>
            </v-tooltip>
            {{ item.name }}
          </template>

          <template v-slot:no-data> Keine Daten gefunden </template>

          <template v-slot:footer.prepend>
            <v-btn
              small
              depressed
              color="white"
              @click="scrollToTop()"
              class="ml-2"
            >
              Nach oben
              <v-icon>mdi-arrow-up-thin</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <v-layout justify-center align-center>
        <v-flex shrink>
          <v-img
            class="mt-16"
            src="@/assets/svg/undraw_void.svg"
            max-width="350px"
            v-if="results.length == 0 && loading == false"
          ></v-img>
        </v-flex> </v-layout
      ><br />
      <h2
        align="center"
        v-if="
          results.length == 0 &&
            fullTextSearchInput.length <= 2 &&
            loading == false
        "
      >
        Es konnten keine Adressen gefunden werden.
      </h2>
      <h2
        align="center"
        v-if="
          results.length == 0 &&
            fullTextSearchInput.length > 2 &&
            loading == false
        "
      >
        Zu dieser Suche konnten keine Adressen gefunden werden.
      </h2>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import addressService from '@/services/crm/addressService.js'
import AddressFilterDialog from '@/components/crm/addresses/AddressFilterDialog.vue'

export default {
  components: {
    AddressFilterDialog
  },
  data: () => ({
    fullTextSearchUrl: '/v1/crm/addresses/search/fullText',
    fullTextSearchInput: '',

    filterSet: false,

    searchSuccessfull: false,
    results: [],
    cancelToken: undefined,
    loading: false,
    searchStartedOnce: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      { text: 'Straße', value: 'street' },
      { text: 'PLZ', value: 'postcode' },
      { text: 'Ort', value: 'city' }
    ]
  }),
  computed: {
    ...mapState({
      filtersActive: state => state.crmFilter.filterActivated
    }),
    ...mapGetters(['getFilter'])
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true
      }
      return false
    },

    initiateSearchUI() {
      this.loading = true
      this.results = []
    },

    searchFullText(query) {
      if (query == undefined) return

      this.initiateSearchUI()
      //Check if there are any previous pending requests
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.')
      }

      //Save the cancel token for the current request
      this.cancelToken = axios.CancelToken.source()

      axios
        .get(this.fullTextSearchUrl, {
          params: { q: query },
          cancelToken: this.cancelToken.token
        })
        .then(response => {
          this.results = response.data
          this.searchSuccessfull = true
          this.loading = false
        })
        .catch(err => {
          console.log('error searching address: ' + err)
        })
    },
    searchTrigger() {
      if (
        this.fullTextSearchInput != null &&
        this.fullTextSearchInput != '' &&
        String(this.fullTextSearchInput).length > 2
      ) {
        this.searchStartedOnce = true
        this.$router.push({ query: { q: this.fullTextSearchInput } })
        this.searchFullText(this.fullTextSearchInput)
      } else {
        this.removeQueryParam()
      }
    },
    setResults(value) {
      this.results = value
    },
    removeQueryParam() {
      this.$router.replace({ query: {} }).catch(() => {})
    },
    openAddress(lfdnr) {
      this.$router.push({ name: 'crmAddress', params: { lfdnr: lfdnr } })
    },
    openAddressDataTable(value) {
      this.openAddress(value.lfdnr)
    },
    addressType(item) {
      return addressService.getAddressTypeDetailed(item.addressTypes[0])
    },
    scrollToTop() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    scrollToResults() {
      const element = this.$refs['resultsTable']
      if (element) {
        this.$nextTick(() => {
          element.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    twoCharactersValidation(value) {
      return (
        this.filtersActive ||
        (!!value && String(value).length > 2) ||
        'Bitte geben Sie mehr als zwei Zeichen ein'
      )
    }
  },
  watch: {
    // Workaround for clearing validation when opening filter
    filtersActive() {
      if (this.filtersActive) {
        this.fullTextSearchInput = ' '
        this.$nextTick(() => {
          this.fullTextSearchInput = ''
        })
      }
    }
  },
  created() {
    window.addEventListener('keydown', event => {
      if (event.key == 'Enter') this.searchTrigger()
    })
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.fullTextSearchInput = this.$route.query['q']
      this.$store.dispatch('unsetFilterActive')
      this.searchTrigger()
    }
  }
}
</script>

<style lang="css" scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
