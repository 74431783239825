<template>
  <div>
    <v-btn
      v-if="!filterSet && !isMobile()"
      block
      class="seondary"
      @click="toggleFilter"
    >
      <v-icon class="mr-2">
        mdi-filter-outline
      </v-icon>

      Filter
    </v-btn>

    <v-btn
      v-else-if="!filterSet && isMobile()"
      block
      class="seondary"
      @click="toggleFilter"
    >
      <v-icon>
        mdi-filter-outline
      </v-icon>
    </v-btn>

    <v-btn
      v-else-if="filterSet && isMobile()"
      block
      class="primary"
      @click="toggleFilter"
    >
      <v-icon>
        mdi-filter
      </v-icon>
    </v-btn>

    <v-btn v-else block class="primary" @click="toggleFilter">
      <v-icon class="mr-2">
        mdi-filter
      </v-icon>

      Filter
    </v-btn>

    <v-dialog v-model="filtersActive" scrollable max-width="1000px" persistent>
      <v-card>
        <v-card-title class="mx-0 px-0 my-0 py-0">
          <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" fixed top dark>
            <v-icon @click="toggleFilter(), isFilterSet()">mdi-close</v-icon>
            <v-toolbar-title class="flex text-center"
              >Adresssuche Filtern <NameSearchHelpDialog
            /></v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text
          ><v-container justify="center" class="mt-15 mb-4" style="width: 95%">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  class="mx-sm-1"
                  outlined
                  label="Name"
                  append-icon="mdi-account"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="strasse"
                  class="mx-sm-1"
                  outlined
                  label="Straße"
                  append-icon="mdi-home"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4" sm="12" xs="12">
                <v-text-field
                  v-model="plz"
                  class="mx-sm-1"
                  outlined
                  label="PLZ"
                  append-icon="mdi-home"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="8" sm="12" xs="12">
                <v-text-field
                  v-model="ort"
                  class="mx-sm-1"
                  outlined
                  label="Ort"
                  append-icon="mdi-home"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4" xl="4">
                <v-select
                  class="mx-sm-1"
                  outlined
                  :items="allAddressTypes"
                  v-model="adrArt"
                  return-object
                  item-text="fldDisplayBez"
                  label="Adr.-Art"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" lg="4" xl="4">
                <v-select
                  class="mx-sm-1"
                  outlined
                  :items="customerGroups"
                  v-model="customerGroup"
                  return-object
                  item-text="bezeichnung"
                  label="Kd. Gruppe"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" lg="4" xl="4">
                <v-select
                  class="mx-sm-1"
                  outlined
                  v-model="kdBranche"
                  :items="branchen"
                  :item-text="item => item.bezeichnung"
                  :item-value="item => item"
                  label="Branche"
                  clearable
                ></v-select>
              </v-col>
            </v-row> </v-container
        ></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="clearFilter(), searchTrigger()"
            >Filter aufheben</v-btn
          >
          <v-btn
            class="primary"
            @click="searchTrigger(), toggleFilter(), isFilterSet()"
          >
            Suchen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
import NameSearchHelpDialog from '@/components/crm/addresses/NameSearchHelpDialog.vue'

export default {
  components: {
    NameSearchHelpDialog
  },
  data() {
    return {
      name: null,
      strasse: null,
      plz: null,
      ort: null,
      adrArt: null,
      customerGroup: null,
      kdBranche: null,

      allAddressTypes: [],
      branchen: [],
      customerGroups: [],

      filtersActiveIndex: 1,
      filterSet: false
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    toggleFilter() {
      this.$store.dispatch('toggleFilterActivated')

      if (this.filtersActive == true) {
        this.fullTextSearchInput = ''
      }
      this.$emit('toggle')
    },
    clearFilter() {
      this.name = null
      this.strasse = null
      this.plz = null
      this.ort = null
      this.adrArt = null
      this.customerGroup = null
      this.kdBranche = null
    },
    isFilterSet() {
      if (
        this.name != null ||
        this.strasse != null ||
        this.plz != null ||
        this.ort != null ||
        this.adrArt != null ||
        this.customerGroup != null ||
        this.kdBranche != null
      ) {
        this.filterSet = true
      } else {
        this.filterSet = false
      }
    },
    resetFilter() {
      this.name = null
      this.strasse = null
      this.plz = null
      this.ort = null
      this.adrArt = null
      this.kdBranche = null
      this.customerGroup = null

      this.$store.dispatch('resetFilter')
    },
    init_filter() {
      const storedFilter = this.getFilter
      this.name = storedFilter.name
      this.strasse = storedFilter.street
      this.plz = storedFilter.postalCode
      this.ort = storedFilter.city

      addressService.getAddressTypes().then(data => {
        this.allAddressTypes = data

        for (let i = 0; i < this.allAddressTypes.length; i++) {
          if (
            JSON.stringify(this.allAddressTypes[i]) ===
            JSON.stringify(storedFilter.addressType)
          ) {
            this.adrArt = storedFilter.addressType
            break
          }
        }
      })
      addressService.getFields().then(data => {
        this.branchen = data

        for (let i = 0; i < this.branchen.length; i++) {
          if (
            JSON.stringify(this.branchen[i]) ===
            JSON.stringify(storedFilter.field)
          ) {
            this.kdBranche = storedFilter.field
            break
          }
        }
      })
      addressService.getCustomerGroups().then(data => {
        this.customerGroups = data
        for (let i = 0; i < this.customerGroups.length; i++) {
          if (
            JSON.stringify(this.customerGroups[i]) ===
            JSON.stringify(storedFilter.customerGroup)
          ) {
            this.customerGroup = storedFilter.customerGroup
            break
          }
        }
      })
    },
    searchTrigger() {
      // Filtered search
      if (this.filtersActive) {
        this.searchStartedOnce = true
        this.removeQueryParam()
        this.searchAddressesFiltered()
      }
    },
    removeQueryParam() {
      this.$router.replace({ query: {} }).catch(() => {})
    },
    searchAddressesFiltered() {
      this.$emit('searchTriggerd')

      var backendSearchFilter = {
        name: this.name,
        strasse: this.strasse,
        plz: this.plz,
        ort: this.ort,
        adrArt: this.adrArt,
        kdGruppe: this.customerGroup,
        branche: this.kdBranche
      }

      this.$store.dispatch('setFilter', {
        name: backendSearchFilter.name,
        street: backendSearchFilter.strasse,
        postalCode: backendSearchFilter.plz,
        city: backendSearchFilter.ort,
        addressType: backendSearchFilter.adrArt,
        customerGroup: backendSearchFilter.kdGruppe,
        field: backendSearchFilter.branche
      })

      addressService.searchAddressesFiltered(backendSearchFilter).then(data => {
        this.loading = false
        this.$emit('searchSuccess', data)
      })
    }
  },
  computed: {
    ...mapState({
      filtersActive: state => state.crmFilter.filterActivated
    }),
    ...mapGetters(['getFilter'])
  },
  mounted() {
    // Needs to get checked here unfortunately, because v-extension-panels seems buggy
    this.filtersActiveIndex = this.filtersActive ? 0 : 1
    this.init_filter()
  }
}
</script>
